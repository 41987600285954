const initPopup = () => {
  const popupDiv = $('[data-popup="hidden"]');
  const time = popupDiv.data('popup-timeout')
  const leave = popupDiv.data('popup-mouseleave')

  sessionStorage.setItem('popuphidden', 'false'); // Default popup state

  const isSend = localStorage.getItem('popupSendaa');

  if(isSend) return;

  if (popupDiv.length === 0) return;

  // initial open
  if (sessionStorage.getItem('popuphidden') != "true" && time != 0) {
    openPopup(popupDiv, time);
  }

  // If user leave the page
  if(leave) {
    $(document).on('mouseleave', function (e) {
        e.preventDefault();
        if(!(sessionStorage.getItem('popuphidden') == "true")) {
          openPopup(popupDiv, 0)
          sessionStorage.setItem('popuphidden', "true")
        }
    });

    $(document).on('touchstart', function(e) {
      if(!(sessionStorage.getItem('popuphidden') == "true")) {
        openPopup(popupDiv, 0);
        sessionStorage.setItem('popuphidden', "true")
      }
    });
  }

  setClosers(popupDiv);
};

const openPopup = (element, delay = 0) => {
    setTimeout(() => {
      element
      .css("display", "flex")
      .hide()
      .fadeIn(300);
    }, delay)
};

const closePopup = (element) => {
  element.fadeOut(300, () => {
    sessionStorage.setItem('popuphidden', "true");
  });
};


const setClosers = (popupDiv) => {
  const closeBtns = $('[data-popup-close]');
  const openBtns = $('[data-popup-open]');
  const popupContent = $('.popup__window');

  // Optional: Prevent the popup from closing when clicking inside
  popupContent.on('click', (e) => {
    e.stopPropagation();
  });

  closeBtns.on('click', () => {
    closePopup(popupDiv);
  });

  openBtns.on('click', () => {
    sessionStorage.setItem('popuphidden', 'false');
    openPopup(popupDiv);
  });
};


$(document).ready(function() {

  initPopup();

	var $menuCheckbox = $('header#mainMenu .hamburger input');
	var $body = $('body');
	$($menuCheckbox).change(function() {
		if ($(this).is(':checked')) {
			$($body).addClass('menuOpen');
		} else {
			$($body).removeClass('menuOpen');
		}
	});
	$('#banner_hp').slick({
		lazyLoad: 'progressive',
		slidesToShow: 1,
		slidesToScroll: 1,
		appendArrows: $('.slick-arrows'),
		autoplay: true,
		autoplaySpeed: 2000,
		speed: 1100,
		pauseOnHover: true
	  });

	  $('.w-submenu > a').on('click', function (e) {
		if (parseInt($(window).width()) < 991) {
		e.preventDefault();
		$(this).parent('li.w-submenu').toggleClass('megamenu-open');
		//$('.w-submenu').removeClass('megamenu-open');
		}
	  });
	  $('li.menu-category > strong > a').on('click', function (e) {
		if (parseInt($(window).width()) < 991) {
		e.preventDefault();
		//$('.category-menu').removeClass('category-open');
		$(this).parent().parent('.menu-category').toggleClass('category-open');
		}
	  });



	$('.news-slick').slick({
		slidesToShow: 4,
		centerMode: true,
		infinite: true,
		swipeToSlide: true,
		centerPadding: '40px',
		arrows: false,
		responsive: [
			{
			  breakpoint: 1200,
			  settings: {
				slidesToShow: 3
			  }
			},
			{
			  breakpoint: 800,
			  settings: {
				slidesToShow: 2
			  }
			},
			{
			  breakpoint: 530,
			  settings: {
				slidesToShow: 1,
				centerPadding: '20px'
			  }
			}
		  ]
	});
	$('.new-service-hp-slick').slick({
		lazyLoad: 'progressive',
		slidesToShow: 1,
		infinite: true,
		centerMode: true,
		swipeToSlide: true,
		speed: 1100,
		//autoplay: true,
		autoplaySpeed: 3000,
		centerPadding: '15%',
		pauseOnHover: true,
		appendArrows: $('.slick-arrows-sale'),
		responsive: [
			{
			  breakpoint: 500,
			  settings: {
				centerPadding: '30px'
			  }
			}
		  ]
	});

	// Work only on SAME page NOT CORS-PAGE - if needed add part with on load
	$('a.easeScroll').click(function(e) {
		e.preventDefault();
		var href = $(this).attr('href');
		var helper = href.split('#');
		var hash = helper[1] || '';

		if (href.charAt(0) == '/' || href.startsWith('http')) {
			var url = helper[0];
			sessionStorage.setItem('hashValue', hash);
			window.location.replace(url);
		} else {
			var element = $('[id=' + hash + ']');
			if ($(window).width() > 767) {
				$('html,body').animate(
					{
						scrollTop: element.offset().top - 85
					},
					960
				);
			} else {
				$('html,body').animate(
					{
						//scrollTop: element.find('h2').offset().top - 60
						scrollTop: element.offset().top - 60
					},
					400
				);
			}
		}

		if ($(this).parent('.navigation')) {
			$('.hamburger input[type=checkbox]').prop('checked', false);
			$('body').removeClass('menuOpen');
		}
	});

});

$(window).on('load', function() {


	// Ease scroll after page reolad
	if (sessionStorage.getItem('hashValue')) {
		var hashValue = sessionStorage.getItem('hashValue');
		sessionStorage.removeItem('hashValue');
	} else {
		sessionStorage.removeItem('hashValue');
	}

	var element;

	if (hashValue) {
		element = $('[id=' + hashValue + ']');
		$('html,body').animate(
			{
				scrollTop: element.offset().top - 85
			},
			600
		);
	}

	// Cookies line

	(function() {
		var $cookiesDiv = $('#cookies');
		var $cookiesCloseBtn = $('a#close-cookie');
		if (!sessionStorage.getItem('cookieconsent')) {
			$($cookiesDiv).fadeIn();
			$cookiesCloseBtn.click(function(e) {
				e.preventDefault();
				$($cookiesDiv).fadeOut().remove();
				sessionStorage.setItem('cookieconsent', true);
			});
		} else {
			$($cookiesDiv).remove();
		}
	})();

	$("a[href^='tel'], a[href^='mailto']").on("click",function(){
		fbq('track', 'Contact');
	});

});
