function initialize() {
	window.map = new google.maps.Map(document.getElementById('map'), {
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		maxZoom: 16,
	});

	// Put domain to var for pathing
	var serverDomain;
	if (window.location.hostname != 'localhost') {
		serverDomain = window.location.host;
	} else {
		serverDomain = window.location.host + '/uklid.to/';
	}

	var infowindow = new google.maps.InfoWindow();

	var bounds = new google.maps.LatLngBounds();

	var marker;

	var markers = locations.map(function(location, i) {
		marker = new google.maps.Marker({
			position: new google.maps.LatLng(location[2], location[1]),
			map: map,
			icon: '//' + serverDomain + '/themes/ditheme/assets/dist/img/map-marker.svg'
		});

		bounds.extend(marker.position);

		google.maps.event.addListener(
			marker,
			'click',
			(function(marker, i) {
				return function() {
					infowindow.setContent('<div class="flex map-box"><div class="image"><img src="'+ location[7] +'" alt="'+ location[0] +'"></div><div class="content"><h4>'+location[0]+'</h4><p>'+ location[3] +'</p><div class="contact"><p>Tel: <strong><a href="tel:'+ location[5] +'">'+ location[5] +'</a></strong></p><p>Email: <strong><a href="mailto:'+ location[4] +'">'+ location[4] +'</a></strong></p><p><a target="_blank" href="'+ location[8] +'">Detail...</a></p></div></div></div>');
					infowindow.open(map, marker);
					//map.setZoom(10);
					//map.setCenter(marker.getPosition());
				};
			})(marker, i)
		);

		// Open info window on initial load
		if(i == 0) {
			infowindow.setContent('<div class="flex map-box"><div class="image"><img src="'+ location[7] +'" alt="'+ location[0] +'"></div><div class="content"><h4>'+location[0]+'</h4><p>'+ location[3] +'</p><div class="contact"><p>Tel: <strong><a href="tel:'+ location[5] +'">'+ location[5] +'</a></strong></p><p>Email: <strong><a href="mailto:'+ location[4] +'">'+ location[4] +'</a></strong></p><p><a target="_blank" href="'+ location[8] +'">Detail...</a></p></div></div></div>');
			infowindow.open(map, marker);
		}

		
		return marker;
	});

	map.fitBounds(bounds);

	var listener = google.maps.event.addListener(map, 'idle', function() {
		//map.setZoom(7);
		google.maps.event.removeListener(listener);
	});
	//getDistanceToStation();
}

function loadScript() {
	var scriptCluster = document.createElement('script');
	scriptCluster.type = 'text/javascript';
	scriptCluster.src = 'https://unpkg.com/@google/markerclustererplus@4.0.1/dist/markerclustererplus.min.js';
	document.body.appendChild(scriptCluster);
	var script = document.createElement('script');
	script.type = 'text/javascript';
	script.src =
		'https://maps.googleapis.com/maps/api/js?key=AIzaSyCBAOTNkcBRa703zuLUi5cQAZVZhdHk4fg&' + 'callback=initialize';
	document.body.appendChild(script);
}

//window.onload = loadScript;

$(document).ready(function () {
	var loaded = false;
	$('#map').on('click', function () {
		if(loaded == false) {
			loadScript();
			loaded = true;
		}
	});
});
